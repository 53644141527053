import Glide from '@glidejs/glide';
import Form from './form';
import map from './map';
import GLightbox from 'glightbox';
/**
 * Created by Michał on 11.05.2017.
 */

let lightbox = new GLightbox();

function detectmob() {
    return ( navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)
    );
}
function toggleMenu(){
    let menu = document.querySelector('#mobile-menu');
    let mask = document.querySelector('#mask');
    let wrapper = document.querySelector('#wrapper');
    let content = document.querySelector('#content');
    document.querySelector('.js-hamburger').classList.toggle("active");
    // document.querySelector('#mask').toggle();
    menu.classList.toggle("toggled");
    mask.classList.toggle('shown');
    content.classList.toggle("toggled");
    if(menu.classList.contains("toggled")) {
        // wrapper.css('height', menu.height() + 'px');
        document.body.style.overflow = 'hidden';
        document.querySelector('html').style.overflow = 'hidden';
    }
    else {
        // wrapper.css('height', 'auto');
        document.body.style.overflow = 'auto';
        document.querySelector('html').style.overflow = 'auto';
    }
}
function toggleBlogMenu(){
    let menu = document.querySelector('#blog-menu');
    let blog_mask = document.querySelector('#blog-mask');
    let wrapper = document.querySelector('#wrapper');
    let content = document.querySelector('#content');
    document.querySelector('.js-blog-hamburger').classList.toggle("active");
    // document.querySelector('#mask').toggle();
    menu.classList.toggle("toggled");
    blog_mask.classList.toggle('shown');
    content.classList.toggle("toggled");
    if(menu.classList.contains("toggled")) {
        // wrapper.css('height', menu.height() + 'px');
        document.body.style.overflow = 'hidden';
        document.querySelector('html').style.overflow = 'hidden';
    }
    else {
        // wrapper.css('height', 'auto');
        document.body.style.overflow = 'auto';
        document.querySelector('html').style.overflow = 'auto';
    }
}


let hamburger = document.querySelector('.js-hamburger');
if(hamburger !== null){
    hamburger.addEventListener('click', event => {
        event.preventDefault();
        toggleMenu();
    });
}

let mask = document.querySelector('#mask');
if (mask !== null) {
    let menu = document.querySelector('#mobile-menu');
    mask.addEventListener('click', (e) => {
        mask.classList.toggle('shown');
        menu.classList.toggle('toggled');
        if(!menu.classList.contains("toggled")) {
            document.body.style.overflow = 'auto';
            document.querySelector('html').style.overflow = 'auto';
        }
    });
}

let mobile_close = document.querySelector('.js-mobile-close');
if (mobile_close != null) {
    mobile_close.addEventListener('click', function(e) {
        let menu = document.querySelector('#mobile-menu');
        if (menu != null && menu.classList.contains('toggled')) {
            let mask = document.querySelector('#mask');

            e.preventDefault();
            document.body.style.overflow = 'initial';
            document.querySelector('html').style.overflow = 'initial';
            menu.classList.remove('toggled');
            mask.classList.remove('shown');
        }
    });
}

window.addEventListener('DOMContentLoaded', () => {

    let carousels = document.querySelectorAll('.js-carousel');
    if (carousels !== null) {
        carousels.forEach(carousel => {
            if(carousel !== null) {
                let m_crls = null;
                let prev_button = carousel.querySelector('[data-element="button-prev"]');
                let next_button = carousel.querySelector('[data-element="button-next"]');
                m_crls = new Glide(carousel, {
                    perView: 3,
                    gap: '10',
                    type: 'carousel',
                    breakpoints: {
                        1250: {
                            perView: 2,
                        },
                        850: {
                            perView: 1,
                        },
                    }
                }).mount();

                prev_button.addEventListener('click', (e) => {
                    e.preventDefault();

                    m_crls.go('<');
                });
                next_button.addEventListener('click', (e) => {
                    e.preventDefault();

                    m_crls.go('>');
                });
            }
        })
    }

    let carouselCompanies = document.querySelectorAll('.js-carousel-companies');
    if (carouselCompanies !== null) {
        carouselCompanies.forEach(carousel => {
            if(carousel !== null) {
                let m_crls = null;
                let prev_button = carousel.querySelector('[data-element="button-prev"]');
                let next_button = carousel.querySelector('[data-element="button-next"]');
                m_crls = new Glide(carousel, {
                    perView: 4,
                    gap: '0',
                    type: 'carousel',
                    breakpoints: {
                        1150: {
                            perView: 3,
                        },
                        850: {
                            perView: 2,
                        },
                        600: {
                            perView: 1,
                        },
                    }
                }).mount();

                prev_button.addEventListener('click', (e) => {
                    e.preventDefault();

                    m_crls.go('<');
                });
                next_button.addEventListener('click', (e) => {
                    e.preventDefault();

                    m_crls.go('>');
                });
            }
        })
    }

    // let ms = null;
    // let main_slider = document.querySelector('.js-homepage-slider');
    // if (main_slider !== null) {
    //     ms = new Glide(main_slider, {
    //         type: 'carousel',
    //         perView: 1,
    //         autoplay: 3500,
    //         animationDuration: 1,
    //         animationTimingFunc: 'ease-in-out',
    //         breakpoints: {
    //             400: {
    //                 autoplay: 4500,
    //             },
    //             600: {
    //                 autoplay: 3500,
    //             }
    //         }
    //     }).mount();
    // }

    let free_exhibition_form = document.querySelector('.js-form');
    if (free_exhibition_form !== null) {
        new Form(free_exhibition_form, {
            action: '/sendmail.php',
            data: element => {
                let data = {
                    first_name: '',
                    email: '',
                    message: '',
                    checkbox: '',
                };
                if (element.querySelector('[name="first_name"]') != null) data.first_name = element.querySelector('[name="first_name"]').value;
                if (element.querySelector('[name="email"]') != null) data.email = element.querySelector('[name="email"]').value;
                if (element.querySelector('[name="message"]') != null) data.message = element.querySelector('[name="message"]').value;
                if (element.querySelector('[name="checkbox"]') != null) data.checkbox = element.querySelector('[name="checkbox"]').value;

                return data;
            },
            beforeSubmit: (element, hashed) => {
                let sending = element.querySelector('.sending');
                sending.style.display = 'flex';
                sending.querySelector('.loader').style.display = 'block';
            },
            success: (element, response) => {
                console.log(response);
                if (response.status === 'success') {
                    let sending = element.querySelector('.sending');
                    let successMessage = sending.querySelector('.success');
                    successMessage.innerHTML = 'Dziękujemy za wypełnienie formularza.';
                    sending.querySelector('.loader').style.display = 'none';
                    element.querySelector('[name="first_name"]').value = '';
                    element.querySelector('[name="email"]').value = '';
                    element.querySelector('[name="message"]').value = '';
                    element.querySelector('[name="checkbox"]').checked = false;
                    successMessage.style.display = 'block';
                    setTimeout(() => {
                        sending.style.display = 'none';
                        successMessage.style.display = 'none';
                        successMessage.innerHTML = '';
                    }, 8000);
                }
            },
            error: (element, response) => {
                console.log(response);
                let sending = element.querySelector('.sending');
                let errorMessage = sending.querySelector('.error');
                errorMessage.innerHTML = 'Przepraszamy wystąpił problem. Odśwież stronę i spróbuj ponownie.';
                sending.querySelector('.loader').style.display = 'none';
                errorMessage.style.display = 'block';
                setTimeout(() => {
                    sending.style.display = 'none';
                    errorMessage.style.display = 'none';
                    errorMessage.innerHTML = '';
                }, 8000);
            }
        });
    }
    let ligthbox = new GLightbox();
    window.initMap = map;
});

window.addEventListener('scroll', () => {
    let interactive_elements = document.querySelectorAll('.interactive-element');

    let window_width = window.innerWidth;
    let window_height = window.innerHeight;
    let window_top = document.body.scrollTop - 100;
    if (document.body.clientWidth <= 600) {
        window_top = document.body.scrollTop - 50;
    }
    let window_bottom = (window_top + window_height);

    for (const inter_element of interactive_elements) {
        let element_height = inter_element.clientHeight;
        let element_top = document.body.scrollTop + inter_element.getBoundingClientRect().top;
        let element_bottom = (element_top + element_height) - 500;

        if ((element_bottom >= window_top) && element_top <= window_bottom) {
            inter_element.classList.add('visible');
        }
    }
});

window.addEventListener('DOMContentLoaded', (event) => {
    setTimeout(() => {
        let interactive_elements = document.querySelectorAll('.interactive-element');

        let window_width = window.innerWidth;
        let window_height = window.innerHeight;
        let window_top = document.body.scrollTop - 100;
        let window_bottom = (window_top + window_height);

        for (const inter_element of interactive_elements) {
            let element_height = inter_element.clientHeight;
            let element_top = document.body.scrollTop + inter_element.getBoundingClientRect().top;
            let element_bottom = (element_top + element_height) - 400;

            if ((element_bottom >= window_top) && element_top <= window_bottom) {
                inter_element.classList.add('visible');
            }
        }
    }, 100);
});

// let observer = new IntersectionObserver((entries, obs)=>{
//     entries.forEach(entry => {
//         if(entry.intersectionRatio > 0.2){
//             observer.unobserve(entry.target);
//             let targets = entry.target.querySelectorAll('.interactive-element');
//             targets.forEach(target => {
//                 target.classList.add('visible');
//             });
//         }
//     });
// }, {root: null, threshold: 0.2});
//
// let interactive_elements = document.querySelectorAll('.js-animation');
// interactive_elements.forEach(element => {
//    observer.observe(element);
// });
